@media screen and (min-width: 600px) {
  .pre-meeting-container {
    padding-right: 20%;
  }
}

.padding-left-style {
  padding-left: 2rem;
}

.padding-top-style {
  padding-top: 1rem;
}
.meeting-title {
  margin-bottom: 5% !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.pre-meeting-container {
  padding-left: 0.6rem;
}

.aua-label-style {
  width: 34.7rem;
}
.dropdown-label {
  margin-bottom: 1rem !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}
.aua-list-item{
  padding-left: 3em;
  
}

@media screen and (min-width: 600px) {
  .form-container {
    max-width: 550px;
  }
}

.style-pd-top {
  padding-top: 1rem;
  width: 34rem;
  word-break: break-word;
}

.submit-btn {
  margin-top: 0.25rem;
}

.copy-button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}